import axios from "axios";

const API_BASE_URL = "https://pjsofttech.in:28443";
// https://pjsofttech.in:28443
// https://pjsofttech.in:28443

export const fetchSlideBarData = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllSlideBars`, {
      params: { institutecode },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch SlideBar data: " + error.message);
  }
};

export const fetchNavbarData = async (instituteCode) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getManuBarByInstitutecode?institutecode=${instituteCode}`
    );
    if (response.data) {
      const navbarData = response.data;
      return {
        navbarColor: navbarData.manuBarColor,
        navbarImage: navbarData.menubarImage,
      };
    }
    return { navbarColor: "#333", navbarImage: "" }; // Default fallback values
  } catch (error) {
    console.error("Error fetching navbar data:", error);
    throw error;
  }
};

export const getToppers = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllToppers`, {
      params: { institutecode },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching toppers data:", error);
    throw error;
  }
};

export const fetchCoursesAPI = async (institutecode) => {
  const response = await fetch(
    `${API_BASE_URL}/getAllCourses?institutecode=${institutecode}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch courses");
  }
  return await response.json();
};

export const fetchTestimonials = async (instituteCode) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getAllTestimonials?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching testimonials:", error);
    throw error;
  }
};

export const fetchMapAndImagesAPI = async (institutecode) => {
  const response = await fetch(
    `${API_BASE_URL}/getMapAndImagesByInstitutecode?institutecode=${institutecode}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch map and images");
  }
  return await response.json();
};

// Define API URL
const API_URL = `${API_BASE_URL}/createContactForm`;

// Function to create the contact form submission
export const createContactForm = async (formData, institutecode) => {
  try {
    const params = new URLSearchParams();
    params.append("name", formData.name);
    params.append("mobileNo", formData.mobileNo);
    params.append("course", formData.course);
    params.append("description", formData.description);
    params.append("email", formData.email);
    params.append("academicYear", formData.academicYear);
    params.append("institutecode", institutecode); // Make sure to provide the institutecode as well

    const response = await axios.post(API_URL, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw new Error("Failed to submit form");
  }
};

// FooterAPI.js
export const fetchFooterByInstitutecode = async (institutecode) => {
  if (!institutecode) {
    console.warn("No institutecode provided");
    return;
  }

  console.log("API call with institutecode:", institutecode);

  try {
    const response = await axios.get(
      `https://pjsofttech.in:28443/getFooterByInstitutecode`,
      { params: { institutecode } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching footer data:", error);
    throw error;
  }
};

export const fetchMenuBarData = async (instituteCode) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getAllManuBars?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching menu bar data:", error);
    return null;
  }
};

// Updated API call function
export const fetchFaculties = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllFacilities`, {
      params: { institutecode },
    });
    return response.data; 
  } catch (error) {
    console.error("Error fetching faculties:", error.response || error);
    throw error;
  }
};

export const fetchAboutUs = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllAboutUs`, {
      params: { institutecode },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching About Us data:", error);
    throw error;
  }
};

export const getVisionMissionByInstituteCode = async (institutecode) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/getVisionMissionByInstitutecode?institutecode=${institutecode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// const API_URL = "https://pjsofttech.in:28443/getAllAwards";

export const getAllAwards = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllAwards`, {
      params: { institutecode },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching awards:", error);
    throw error;
  }
};

export const getAllJobCareerOptions = async (institutecode) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllJobCareerOptions`, {
      params: { institutecode },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching job career options:", error);
    throw error;
  }
};
