// import React, { createContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';

// export const InstitutecodeContext = createContext();

// const InstitutecodeProvider = ({ children }) => {
//   const location = useLocation();
//   const [institutecode, setInstitutecode] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const pathSegments = location.pathname.split("/");
//     const dynamicPart = pathSegments.length > 2 ? pathSegments[2] : null;

//     if (dynamicPart) {
//       axios
//         .get(`https://pjsofttech.in:28443/api/urls/getInstitutecodeByDynamicPart/${dynamicPart}`)
//         .then((response) => {
//           setInstitutecode(response.data || "defaultCode");
//         })
//         .catch((error) => {
//           console.error("Failed to fetch institutecode:", error);
//           setInstitutecode("defaultCode");
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     } else {
//       setInstitutecode("defaultCode");
//       setLoading(false);
//     }
//   }, [location.pathname]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <InstitutecodeContext.Provider value={institutecode}>
//       {children}
//     </InstitutecodeContext.Provider>
//   );
// };

// export default InstitutecodeProvider;

import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const InstitutecodeContext = createContext();

const InstitutecodeProvider = ({ children }) => {
  const location = useLocation();
  const [institutecode, setInstitutecode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const dynamicPart = pathSegments.length > 2 ? pathSegments[2] : null;

    if (dynamicPart) {
      axios
        .get(`https://pjsofttech.in:28443/api/urls/getInstitutecodeByDynamicPart/${dynamicPart}`)
        .then((response) => {
          setInstitutecode(response.data || "defaultCode");
        })
        .catch((error) => {
          console.error("Failed to fetch institutecode:", error);
          setInstitutecode("defaultCode");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setInstitutecode("defaultCode");
      setLoading(false);
    }
  }, [location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <InstitutecodeContext.Provider value={institutecode}>
      {children}
    </InstitutecodeContext.Provider>
  );
};

export default InstitutecodeProvider;
