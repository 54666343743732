
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Courses from "../Courses";
import SlideBar from "../SlideBar";
import Testimonials from "../Testimonials";
import Faculties from "../Faculty";
import Topper from "../Topper";
import { Typography, Box } from "@mui/material";
import MapAndImages from "../ContactUs";
import Galleries from "../Gallary";

const Home = ({ setCurrentView }) => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });
  }, []);

  return (
    <>
      {/* SlideBar Animation */}
      <div data-aos="fade-down">
        <SlideBar />
      </div>

      {/* Courses Section */}
      <div data-aos="fade-right">
        <Courses showAllCourses={false} cardAnimation="flip-left" />
      </div>

      <div data-aos="zoom-in" style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={() => setCurrentView("courses")}>
          View All Courses
        </button>
      </div>

      {/* Faculties Section */}
      <div data-aos="fade-left">
        <Faculties showOnHome={true} cardAnimation="fade-up" />
      </div>

      <div data-aos="zoom-in" style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={() => setCurrentView("faculty")}>
          View All Faculties
        </button>
      </div>

      {/* Gallery Section  */}
      <Box display="flex" alignItems="center" justifyContent="center" style={sectionMargin}>
      <div className="line" />
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "240px", // Space between the line and the container's left edge
          }}
        ></div>
        <div data-aos="fade-up">
          <SectionHeader
           title="GALLERY" />
        </div>
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "240px", // Space between the line and the container's right edge
          }}
        ></div>
        <div className="line" />
      </Box>

      <div data-aos="zoom-in">
        <Galleries
          showLimited={true}
          hideFilters={true}
          imageAnimation="zoom-in"
        />
      </div>

      <div data-aos="zoom-in" style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={() => setCurrentView("gallery")}>
          View Gallery
        </button>
      </div>

      {/* Toppers Section */}
      <div data-aos="flip-up">
        <Topper showOnHome={true} cardAnimation="flip-right" />
      </div>

      <div data-aos="zoom-in" style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={() => setCurrentView("toppers")}>
          View All Toppers
        </button>
      </div>

      {/* Testimonials Section */}
      <div data-aos="fade-up">
        <Testimonials showOnHome={true} cardAnimation="fade-in" />
      </div>

      <div data-aos="zoom-in" style={buttonContainerStyle}>
        <button
          style={buttonStyle}
          onClick={() => setCurrentView("testimonials")}
        >
          View All Testimonials
        </button>
      </div>

      {/* Contact Us Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={sectionMargin}
      >
        <div className="line" />
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "240px", // Space between the line and the container's left edge
          }}
        ></div>
        <div data-aos="fade-up">
          <SectionHeader title="CONTACT US" />
        </div>
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "240px", // Space between the line and the container's right edge
          }}
        ></div>
        <div className="line" />
      </Box>

      <div data-aos="slide-up">
        <MapAndImages cardAnimation="zoom-in" />
      </div>
    </>
  );
};

// 🔹 Reusable Section Header Component 🔹
const SectionHeader = ({ title }) => (
  <Box display="flex" alignItems="center" justifyContent="center" width="100%">
    <div style={halfLineStyle}></div>
    <Typography variant="h4" style={headerStyle}>
      {title}
    </Typography>
    <div style={halfLineStyle}></div>
  </Box>
);

// 🔹 Styles 🔹
const buttonStyle = {
  padding: "10px 20px",
  backgroundColor: "#0086B9",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

const buttonContainerStyle = { textAlign: "center", margin: "20px 0" };

const sectionMargin = { marginTop: "40px", marginBottom: "20px" };

const headerStyle = {
  fontWeight: "bold",
  textAlign: "center",
  margin: "0 20px", // Space between text and lines
  color: "#0086B9",
  whiteSpace: "nowrap", // Prevents text from breaking
};

const halfLineStyle = {
  flex: 1,
  height: "3px",
  backgroundColor: "#0086B9",
  width: "50%", // Ensures each line takes 50% of the width
};

export default Home;
