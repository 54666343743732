import React, { useContext, useEffect, useState } from 'react';
import { getAllAwards } from './API/AllApi';
import { Container, Typography, Card, CardContent, CircularProgress, Grid, CardMedia, Button, Box } from '@mui/material';
import { InstitutecodeContext } from "./InstitutecodeProvider";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AwardsList = () => {
    const [awards, setAwards] = useState([]);
    const [filteredAwards, setFilteredAwards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null); // Updated to hold the selected year
    const institutecode = useContext(InstitutecodeContext);

    useEffect(() => {
        AOS.init({ duration: 1000 });

        const fetchAwards = async () => {
            try {
                const data = await getAllAwards(institutecode.data.institutecode);
                setAwards(data);
                setFilteredAwards(data); // Initially display all awards
            } catch (err) {
                setError('Failed to fetch awards');
            } finally {
                setLoading(false);
            }
        };

        fetchAwards();
    }, [institutecode.data.institutecode]);

    const handleYearClick = (year) => {
        setSelectedYear(year);
        if (year) {
            // Filter awards by selected year
            const filtered = awards.filter(award => award.year === parseInt(year));
            setFilteredAwards(filtered);
        } else {
            // Show all awards if no year is selected
            setFilteredAwards(awards);
        }
    };

    const years = [];
    for (let i = 2025; i >= 2015; i--) {
        years.push(i);
    }

    if (loading) return <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 3 }} />;
    if (error) return <Typography color="error" align="center">{error}</Typography>;

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Typography
                variant="h4"
                style={{
                    color: "#0086B9",
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: "0 20px 0 20px",
                    paddingBottom: "30px"
                }}
                data-aos="fade-down"
            >
                Awards and Accolades
            </Typography>

            {/* Navbar for Year Selection */}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: 3 }}>
                <Button
                    variant="outlined"
                    sx={{
                        margin: 0.5,
                        borderRadius: 2,
                        backgroundColor: !selectedYear ? '#0086B9' : 'transparent',
                        color: !selectedYear ? '#fff' : '#0086B9',
                        '&:hover': {
                            backgroundColor: !selectedYear ? '#006c8f' : '#f1f1f1',
                            color: '#0086B9',
                        }
                    }}
                    onClick={() => handleYearClick(null)}
                >
                    All Years
                </Button>
                {years.map((year) => (
                    <Button
                        key={year}
                        variant="outlined"
                        sx={{
                            margin: 0.5,
                            borderRadius: 2,
                            backgroundColor: selectedYear === year ? '#0086B9' : 'transparent',
                            color: selectedYear === year ? '#fff' : '#0086B9',
                            '&:hover': {
                                backgroundColor: selectedYear === year ? '#006c8f' : '#f1f1f1',
                                color: '#0086B9',
                            }
                        }}
                        onClick={() => handleYearClick(year)}
                    >
                        {year}
                    </Button>
                ))}
            </Box>

            <Grid container spacing={3} justifyContent="center">
                {filteredAwards.map((award) => (
                    <Grid item xs={12} sm={6} md={4} key={award.id} data-aos="fade-up">
                        <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                            {award.awardImage && (
                                <CardMedia
                                    component="img"
                                    height="220"
                                    image={award.awardImage}
                                    alt={award.awardName}
                                    sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                                />
                            )}
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{award.awardName}</Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }} style={{ marginBottom: "4px" }}>{award.description}</Typography>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: "3px" }}><b>Awarded By: </b> {award.awardedBy}</Typography>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: "3px" }}><b>Awarded To: </b>{award.awardTo}</Typography>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: "3px" }}><b>Year: </b>{award.year}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default AwardsList;
