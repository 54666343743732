import React, { useContext, useEffect, useState } from "react";
import "./CSS/SlideBar.css";
import { fetchSlideBarData } from "./API/AllApi";
import { InstitutecodeContext } from "./InstitutecodeProvider";

const SlideBar = () => {
  const [slideBarData, setSlideBarData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchSlideBarData(institutecode.data.institutecode);
        if (!data || !Array.isArray(data.slideImages)) {
          throw new Error("Invalid response structure");
        }

        setSlideBarData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to load slides.");
        setLoading(false);
      }
    };

    getData();
  }, [institutecode.data.institutecode]);

  useEffect(() => {
    if (slideBarData && slideBarData.slideImages.length > 0) {
      const interval = setInterval(() => {
        setCurrentSlide(
          (prevSlide) => (prevSlide + 1) % slideBarData.slideImages.length
        );
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [slideBarData]);

  if (loading)
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading slides...</p>
      </div>
    );

  if (error) {
    console.error("Error fetching slide data:", error);
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  if (
    !slideBarData ||
    !slideBarData.slideImages ||
    slideBarData.slideImages.length === 0
  ) {
    return (
      <div className="no-data-container">
        <p>No slides available to display.</p>
      </div>
    );
  }

  return (
    <div className="slideshow-container">
      <div
        className="slides"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slideBarData.slideImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className="slide-image lazy"
            loading="lazy"
            onError={(e) => {
              e.target.src = "/path/to/fallback-image.jpg";
              e.target.alt = "Fallback image";
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideBar;