

import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2"; // Import SweetAlert
import { createContactForm, fetchCoursesAPI } from "./API/AllApi";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import "./CSS/Design.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    course: "",
    description: "",
    email: "",
    academicYear: "",
  });

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const data = await fetchCoursesAPI(institutecode.data.institutecode);
        setCourses(data);
      } catch (err) {
        console.error("Error fetching courses:", err);
      }
    };

    fetchCourses();
  }, [institutecode.data.institutecode]);

  // Handle change in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      await createContactForm(formData, institutecode.data.institutecode); // API call
      setFormData({
        name: "",
        mobileNo: "",
        course: "",
        description: "",
        email: "",
        academicYear: "",
      });

      // Show SweetAlert on success
      Swal.fire({
        icon: "success",
        title: "Form Submitted!",
        text: "Your contact form has been successfully submitted.",
        confirmButtonColor: "#0086B9",
      });
    } catch (err) {
      setError("Failed to submit the form. Please try again.");

      // Show SweetAlert on error
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an error submitting your form. Please try again.",
        confirmButtonColor: "#d33",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
        <Paper elevation={3} style={{ padding: "20px", width: "100%" }}>
          <Typography
            variant="h5"
            marginBottom={2}
            align="center"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "0 20px",
              color: "#0086B9",
            }}
          >
            Contact Form
          </Typography>

          {error && (
            <Typography variant="body2" color="error" marginBottom={2}>
              {error}
            </Typography>
          )}

          <form onSubmit={handleSubmit} style={{ width: "100%" }} className="textField-root">
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleInputChange}
              margin="normal"
              required
            />

            <TextField
              label="Academic Year"
              variant="outlined"
              fullWidth
              name="academicYear"
              value={formData.academicYear}
              onChange={handleInputChange}
              margin="normal"
              required
            />

            <FormControl fullWidth margin="normal" required>
              <InputLabel>Course</InputLabel>
              <Select name="course" value={formData.course} onChange={handleInputChange}>
                {courses.map((course) => (
                  <MenuItem key={course.courseId} value={course.courseTitle}>
                    {course.courseTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
              required
            />

            <Button
              variant="contained"
              color="info"
              type="submit"
              fullWidth
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default ContactForm;
