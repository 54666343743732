import React, { useContext, useEffect, useState } from "react";
import { fetchAboutUs } from "./API/AllApi";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CSS/AboutUs.css"; // Import the CSS file

const AboutUs = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-out",
      once: true,
    });
  }, []);

  useEffect(() => {
    const getAboutUsData = async () => {
      try {
        const data = await fetchAboutUs(institutecode.data.institutecode);
        console.log("API Response:", data);
        setAboutUsData(data);
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      } finally {
        setLoading(false);
      }
    };

    getAboutUsData();
  }, [institutecode.data.institutecode]);

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  if (!aboutUsData) {
    return (
      <Typography variant="h6" align="center" color="text.secondary">
        No About Us data available.
      </Typography>
    );
  }

  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <Grid container spacing={8} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} data-aos="fade-right">
            <Typography variant="h4" className="about-us-title">
              ABOUT US
            </Typography>
            <Typography variant="h5" gutterBottom>
              {aboutUsData.aboutUsTitle}
            </Typography>
            <Typography variant="body1" color="text.secondary" className="about-us-description">
              {aboutUsData.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="about-us-image-container" data-aos="fade-left">
            <img src={aboutUsData.aboutUsImage} alt={aboutUsData.aboutUsTitle} className="about-us-image" />
            <img src="https://www.vgos.org/public/frontend/images/about-home-slider-icon1.png" alt="Top-left Icon" className="about-us-icon top-left-icon" data-aos="zoom-in" />
            <img src="https://www.vgos.org/public/frontend/images/about-home-slider-icon3.png" alt="Top-right Icon" className="about-us-icon top-right-icon" data-aos="fade-down" />
            <img src="https://www.vgos.org/public/frontend/images/about-home-slider-icon4.png" alt="Bottom-right Icon" className="about-us-icon bottom-right-icon" data-aos="flip-up" />
          </Grid>
        </Grid>
      </div>
      <style>
        {`
          @keyframes zoomInOut {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
          }
          @keyframes circularTransition {
            0% {
              transform: rotate(0deg) scale(1);
            }
            50% {
              transform: rotate(180deg) scale(1.2);
            }
            100% {
              transform: rotate(360deg) scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default AboutUs;