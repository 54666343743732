
// import React, { useContext, useEffect, useState } from "react";
// import "./CSS/Testimonials.css";
// import { fetchTestimonials } from "./API/AllApi";
// import { InstitutecodeContext } from "./InstitutecodeProvider";
// import { Box, Typography } from "@mui/material";
// import "@fontsource/poppins"; // Defaults to 400 weight
// import "@fontsource/poppins/700.css"; // Example for bold text

// const Testimonials = ({ showOnHome = false }) => {
//   const [testimonials, setTestimonials] = useState([]);
//   const [error, setError] = useState(null);
//   const institutecode = useContext(InstitutecodeContext);

//   useEffect(() => {
//     const getTestimonials = async () => {
//       try {
//         const data = await fetchTestimonials(institutecode.data.institutecode);
//         setTestimonials(data);
//       } catch (err) {
//         setError(err);
//       }
//     };
//     getTestimonials();
//   }, [institutecode.data.institutecode]);

//   if (error) {
//     return <div>Error loading testimonials: {error.message}</div>;
//   }

//   if (testimonials.length === 0) {
//           return (
//             <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//               <Typography variant="h4" color="textSecondary">
//                 No Toppers available at the moment.
//               </Typography>
//             </Box>
//           );
//         }

//   const displayedTestimonials = showOnHome
//     ? testimonials.slice(0, 4) // Show first 4 testimonials on the home page
//     : testimonials;

//   return (
//     <div className="testimonials-container">
//       {/* <h2 className="testimonials-heading">
//         {showOnHome ? "What People Say" : "Our Testimonials"}
//       </h2> */}
//       <Box
//   display="flex"
//   alignItems="center"
//   justifyContent="center"
//   style={{ marginTop: "40px", marginBottom: "20px" }}
// >
//   <div
//     style={{
//       flex: "1",
//       height: "3px",
//       backgroundColor: "#0086B9",
//       marginLeft: "240px", // Space between the line and the container's left edge
//     }}
//   ></div>
//   <Typography
//     variant="h4"
//     style={{ fontWeight: "bold", textAlign: "center", margin: "0 20px", marginBottom: "20px", color:'#0086B9'}}
//   >
//     WHAT PEOPLE SAYS
//   </Typography>
//   <div
//     style={{
//       flex: "1",
//       height: "3px",
//       backgroundColor: "#0086B9",
//       marginRight: "240px", // Space between the line and the container's right edge
//     }}
//   ></div>
// </Box>

//       <div className="testimonials-grid">
//         {displayedTestimonials.map((testimonial) => (
//           <div key={testimonial.testimonialId} className="testimonial-card">
//             {/* Description Section */}
//             <p
//               className="testimonial-description"
//               style={{ textAlign: "left", marginBottom: "16px" }}
//             >
//               {testimonial.description}
//             </p>
//             {/* Image and Details Section */}
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "left",
//                 marginBottom: "8px",
//               }}
//             >
//               <img
//                 src={testimonial.testimonialImage}
//                 alt={testimonial.testimonialName}
//                 className="testimonial-image"
//                 style={{
//                   width: "80px",
//                   height: "80px",
//                   borderRadius: "50%",
//                   marginRight: "12px",
//                 }}
//               />
//               <div>
//                 <h3
//                   className="testimonial-name"
//                   style={{ margin: "0", fontSize: "1.1rem" }}
//                 >
//                   {testimonial.testimonialName}
//                 </h3>
//                 <div
//                   style={{
//                     fontSize: "0.9rem",
//                     color: "#555",
//                     marginTop: "4px",
//                   }}
//                 >
//                   <span className="testimonial-post">
//                     {testimonial.post}
//                   </span>
//                   <span style={{ margin: "0 8px" }}>|</span>
//                   <span className="testimonial-exam">
//                     {testimonial.exam}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Testimonials;


import React, { useContext, useEffect, useState } from "react";
import "./CSS/Testimonials.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { fetchTestimonials } from "./API/AllApi";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import { Box, Typography } from "@mui/material";
import "@fontsource/poppins"; // Defaults to 400 weight
import "@fontsource/poppins/700.css"; // Example for bold text

const Testimonials = ({ showOnHome = false }) => {
  const [testimonials, setTestimonials] = useState([]);
  const [error, setError] = useState(null);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS animations
  }, []);

  useEffect(() => {
    const getTestimonials = async () => {
      try {
        const data = await fetchTestimonials(institutecode.data.institutecode);
        setTestimonials(data);
      } catch (err) {
        setError(err);
      }
    };
    getTestimonials();
  }, [institutecode.data.institutecode]);

  if (error) {
    return <div>Error loading testimonials: {error.message}</div>;
  }

  if (testimonials.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" color="textSecondary">
          No Toppers available at the moment.
        </Typography>
      </Box>
    );
  }

  const displayedTestimonials = showOnHome
    ? testimonials.slice(0, 4) // Show first 4 testimonials on the home page
    : testimonials;

  return (
    <div className="testimonials-container">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "240px",
          }}
        ></div>
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", textAlign: "center", margin: "0 20px", marginBottom: "20px", color: '#0086B9' }}
        >
          WHAT PEOPLE SAY
        </Typography>
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "240px",
          }}
        ></div>
      </Box>

      <div className="testimonials-grid">
        {displayedTestimonials.map((testimonial, index) => (
          <div
            key={testimonial.testimonialId}
            className="testimonial-card"
            data-aos="fade-up"
            data-aos-delay={index * 150} // Adds a slight staggered effect
          >
            {/* Description Section */}
            <p className="testimonial-description" style={{ textAlign: "left", marginBottom: "16px" }}>
              {testimonial.description}
            </p>
            {/* Image and Details Section */}
            <div style={{ display: "flex", alignItems: "left", marginBottom: "8px" }}>
              <img
                src={testimonial.testimonialImage}
                alt={testimonial.testimonialName}
                className="testimonial-image"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  marginRight: "12px",
                }}
              />
              <div>
                <h3 className="testimonial-name" style={{ margin: "0", fontSize: "1.1rem" }}>
                  {testimonial.testimonialName}
                </h3>
                <div style={{ fontSize: "0.9rem", color: "#555", marginTop: "4px" }}>
                  <span className="testimonial-post">{testimonial.post}</span>
                  <span style={{ margin: "0 8px" }}>|</span>
                  <span className="testimonial-exam">{testimonial.exam}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
