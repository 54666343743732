// import React, { useContext, useEffect, useState } from "react";
// import { Grid, Typography, CircularProgress, Box } from "@mui/material";
// import "./CSS/Faculty.css"; // Your custom styles
// import { fetchFaculties } from "./API/AllApi";
// import { InstitutecodeContext } from "./InstitutecodeProvider";

// const Faculties = ({ showOnHome = false }) => {
//   const [faculties, setFaculties] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const institutecode = useContext(InstitutecodeContext);

//   useEffect(() => {
//     const getFaculties = async () => {
//       try {
//         const data = await fetchFaculties(institutecode.data.institutecode);
//         setFaculties(data);
//         setLoading(false);
//       } catch (err) {
//         setError(err);
//         setLoading(false);
//       }
//     };

//     getFaculties();
//   }, [institutecode.data.institutecode]);

//   if (loading) {
//     return (
//       <div
//         style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error loading faculties: {error.message}</div>;
//   }

//   if (faculties.length === 0) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh"
//       >
//         <Typography variant="h4" color="textSecondary">
//           No Faculties available at the moment.
//         </Typography>
//       </Box>
//     );
//   }

//   const displayedFaculties = showOnHome ? faculties.slice(0, 4) : faculties;

//   return (
//     <>
//       {/* <div className="faculties-container"> */}
//       <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px" }}>
//       <div
//           style={{
//             flex: "1",
//             height: "3px",
//             backgroundColor: "#0086B9",
//             marginLeft: "240px", // Space between the line and the container's left edge
//           }}
//         ></div>
//         <Typography variant="h4" style={{ fontWeight: "bold", textAlign: "center", margin: "0 20px",color:'#0086B9' }}>
//           {showOnHome ? "MEET OUR FACULTIES" : "FACULTIES"}
//         </Typography>
//         <div
//           style={{
//             flex: "1",
//             height: "3px",
//             backgroundColor: "#0086B9",
//             marginRight: "240px", // Space between the line and the container's right edge
//           }}
//         ></div>
//       <div className="line" />
//       </Box>

//         <Grid container spacing={3} justifyContent="center">
//           {displayedFaculties.map((faculty) => {
//             const color = faculty.facilityColor || "#ffffff";

//             return (
//               <Grid
//                 item
//                 key={faculty.facilityId}
//                 xs={12}
//                 sm={6}
//                 md={3}
//                 style={{ display: "flex", justifyContent: "center" }}
//               >
//                 <div
//                   className="faculty-card"
//                   style={{
//                     backgroundColor: color,
//                     // height: "350px",
//                     // width: "100%",
//                   }}
//                   data-hover-bg={`rgba(${parseInt(
//                     color.slice(1, 3),
//                     16
//                   )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
//                     color.slice(5, 7),
//                     16
//                   )}, 0.3)`} // Dynamic hover color
//                 >
//                   <div
//                     className="faculty-image"
//                     style={{
//                       backgroundImage: `url(${
//                         faculty.facilityImage || "/path/to/fallback-image.jpg"
//                       })`,
//                     }}
//                   ></div>
//                   <div className="faculty-info">
//                     <Typography
//                       variant="h5"
//                       gutterBottom
//                       style={{
//                         paddingBottom: "-20px",
//                         textShadow: "5px 5px 10px rgba(0, 0, 0, 0.9)",
//                       }}
//                     >
//                       {faculty.facilityName}
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       Subject: {faculty.subject}
//                     </Typography>
//                     <Typography variant="body2" gutterBottom>
//                       Qualification: {faculty.facilityEducation}
//                     </Typography>
//                     <Typography variant="body2" gutterBottom>
//                       Experience: {faculty.experienceInYear} years
//                     </Typography>
//                     {/* <Typography variant="body2" gutterBottom>
//                     {faculty.description}
//                   </Typography> */}
//                   </div>
//                 </div>
//               </Grid>
//             );
//           })}
//         </Grid>
//       {/* </div> */}
//     </>
//   );
// };

// export default Faculties;


import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Grid, Typography, CircularProgress, Box } from "@mui/material";
import "./CSS/Faculty.css"; // Your custom styles
import { fetchFaculties } from "./API/AllApi";
import { InstitutecodeContext } from "./InstitutecodeProvider";

const Faculties = ({ showOnHome = false }) => {
  const [faculties, setFaculties] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const institutecode = useContext(InstitutecodeContext);
  const [title, setTitle] = useState("");


  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await fetch("https://pjsofttech.in:28443/getAllFacilityTitle?institutecode=ABC124");
        if (!response.ok) {
          throw new Error("Failed to fetch title");
        }
        const data = await response.json();
        setTitle(data.title); // Assuming API returns { title: "Your Title Here" }
      } catch (error) {
        console.error("Error fetching title:", error);
      }
    };

    fetchTitle();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, easing: "ease-in-out", once: true });

    const getFaculties = async () => {
      try {
        const data = await fetchFaculties(institutecode.data.institutecode);
        setFaculties(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    getFaculties();
  }, [institutecode.data.institutecode]);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading faculties: {error.message}</div>;
  }

  if (faculties.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h4" color="textSecondary">
          No Faculties available at the moment.
        </Typography>
      </Box>
    );
  }

  const displayedFaculties = showOnHome ? faculties.slice(0, 4) : faculties;

  return (
    <>
      {/* Section Header with Animation */}
      <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px" }}>
      <div style={{ flex: "1", height: "3px", backgroundColor: "#0086B9", marginLeft: "240px" }}></div>
      <Typography
        variant="h4"
        data-aos="fade-down"
        style={{ fontWeight: "bold", textAlign: "center", margin: "0 20px", color: "#0086B9" }}
      >
        {title || (showOnHome ? "MEET OUR FACULTIES" : "FACULTIES")}
      </Typography>
      <div style={{ flex: "1", height: "3px", backgroundColor: "#0086B9", marginRight: "240px" }}></div>
    </Box>
      {/* Faculties Grid with Animations */}
      <Grid container spacing={3} justifyContent="center">
        {displayedFaculties.map((faculty, index) => {
          const color = faculty.facilityColor || "#ffffff";

          return (
            <Grid
              item
              key={faculty.facilityId}
              xs={12}
              sm={6}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
              data-aos={index % 2 === 0 ? "fade-up" : "zoom-in"} // Alternating animations
            >
              <div
                className="faculty-card"
                style={{
                  backgroundColor: color,
                }}
                data-hover-bg={`rgba(${parseInt(
                  color.slice(1, 3),
                  16
                )}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                  color.slice(5, 7),
                  16
                )}, 0.3)`} // Dynamic hover color
              >
                <div
                  className="faculty-image"
                  style={{
                    backgroundImage: `url(${faculty.facilityImage || "/path/to/fallback-image.jpg"})`,
                  }}
                ></div>
                <div className="faculty-info">
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      paddingBottom: "-20px",
                      textShadow: "5px 5px 10px rgba(0, 0, 0, 0.9)",
                    }}
                  >
                    {faculty.facilityName}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Subject: {faculty.subject}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Qualification: {faculty.facilityEducation}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Experience: {faculty.experienceInYear} years
                  </Typography>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Faculties;

