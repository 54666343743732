import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { InstitutecodeContext } from "./InstitutecodeProvider";

const API_BASE_URL = "https://pjsofttech.in:28443";

export const Topper = ({ showOnHome = false }) => {
  const [toppers, setToppers] = useState([]);
  const [filteredToppers, setFilteredToppers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(2025);
  const institutecode = useContext(InstitutecodeContext);
  const code = institutecode.data.institutecode;

  useEffect(() => {
    // Initialize AOS animations
    AOS.init({ duration: 1000, easing: "ease-in-out", once: true });

    axios
      .get(`${API_BASE_URL}/getAllToppers`, { params: { institutecode: code } })
      .then((response) => {
        const sortedToppers = response.data.sort((a, b) => a.rank - b.rank);
        setToppers(sortedToppers);
        setFilteredToppers(sortedToppers.filter((topper) => topper.year === 2025));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching toppers data:", error);
        setLoading(false);
      });
  }, [code]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setFilteredToppers(toppers.filter((topper) => topper.year === year));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (toppers.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5" color="textSecondary">
          No Toppers available at the moment.
        </Typography>
      </Box>
    );
  }

  const displayedToppers = showOnHome ? filteredToppers.slice(0, 4) : filteredToppers;

  return (
    <>
      {/* Section Title with Animation */}
      <Box display="flex" alignItems="center" justifyContent="center" my={4} data-aos="fade-down">
        <div className="line" />
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "240px",
          }}
        ></div>
        <Typography variant="h4" fontWeight="bold" color="#0086B9">
          &nbsp; TOPPERS &nbsp;
        </Typography>
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "240px",
          }}
        ></div>
        <div className="line" />
      </Box>

      {/* Year Selection (Not shown on Home Page) */}
      {!showOnHome && (
        <AppBar position="static" color="primary">
          <Toolbar style={{ justifyContent: "center" }}>
            {Array.from({ length: 2025 - 2018 + 1 }, (_, index) => 2025 - index).map((year) => (
              <Button
                key={year}
                color={year === selectedYear ? "secondary" : "inherit"}
                onClick={() => handleYearChange(year)}
                sx={{
                  mx: 1,
                  fontSize: "14px",
                  color: year === selectedYear ? "yellow" : "white",
                }}
              >
                {year}
              </Button>
            ))}
          </Toolbar>
        </AppBar>
      )}

      {/* Toppers Grid with AOS Slide Animations */}
      <Grid container spacing={3} sx={{ mt: 2, px: { xs: 2, md: 6 } }}>
        {displayedToppers.map((topper, index) => {
          // Assign animations: Left cards slide from left, Right cards slide from right
          let animationEffect;
          if (index % 2 === 0) animationEffect = "fade-right"; // Left side cards
          else animationEffect = "fade-left"; // Right side cards

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={topper.topperId} data-aos={animationEffect}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  overflow: "hidden",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.03)" },
                   objectFit: "contain", display: "block"
                }}
                // style={{ objectfit: "contain", display: "block" }}
              >
                <CardMedia component="img" height="280" image={topper.topperImage} alt={topper.name} sx={{objectFit: "contain", display: "block"}}/>
                <CardContent sx={{ backgroundColor: "#f9f9f9", py: 2 }}>
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {topper.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Rank:</b> {topper.rank}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Total Marks:</b> {topper.totalMarks}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Year:</b> {topper.year}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Post:</b> {topper.post}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Topper;


