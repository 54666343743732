import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Card,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import { fetchCoursesAPI } from "./API/AllApi";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import "./CSS/Courses.css";
import CourseDetails from "./CourseDetails";

const Courses = ({ showAllCourses = false }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    AOS.init({ duration: 1200, easing: "ease-in-out", once: true });

    const fetchCourses = async () => {
      try {
        const data = await fetchCoursesAPI(
          institutecode?.data?.institutecode || "DEFAULT_CODE"
        );
        setCourses(data);
      } catch (err) {
        console.error("Error fetching courses:", err);
        setError("Failed to load courses. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [institutecode?.data?.institutecode]);

  const handleOpenCourseDetails = (course) => {
    setSelectedCourse(course);
  };

  const handleCloseCourseDetails = () => {
    setSelectedCourse(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
        <Typography variant="body1" style={{ marginLeft: "1rem" }}>
          Loading courses...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (selectedCourse) {
    return (
      <CourseDetails
        course={selectedCourse}
        handleClose={handleCloseCourseDetails}
      />
    );
  }

  const displayedCourses = showAllCourses ? courses : courses.slice(0, 3);

  return (
    <Box>
      <Typography
        variant="h4"
        align="center"
        data-aos="fade-down"
        style={{
          margin: "20px 0",
          fontWeight: "bold",
          color: "#0086B9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            flex: 1,
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "250px",
          }}
        ></span>
        &nbsp; COURSES &nbsp;
        <span
          style={{
            flex: 1,
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "250px",
          }}
        ></span>
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {displayedCourses.map((course, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={course.courseId}
            data-aos={index % 2 === 0 ? "fade-up" : "fade-left"}
          >
            <Card
              className="card"
              style={{
                backgroundImage: `url(${
                  course.courseImage || "/fallback.jpg"
                })`,
              }}
            >
              <div
                className="card-title-bar"
                style={{ backgroundColor: `${course.courseColor}cc` }}
              >
                <Typography variant="h6">{course.courseTitle}</Typography>
              </div>
              <div
                className="overlay"
                style={{ backgroundColor: `${course.courseColor}aa` }}
              >
                <Typography variant="h6">{course.courseTitle}</Typography>
                <Typography variant="body2">{course.description}</Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop="10px"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenCourseDetails(course)}
                  >
                    Know More
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(course.link, "_blank")}
                  >
                    Join Now
                  </Button>
                </Box>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Courses;
