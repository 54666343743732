// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { Grid, TextField, MenuItem, Box, Typography } from "@mui/material";
// import { InstitutecodeContext } from "./InstitutecodeProvider";
// import Masonry from "@mui/lab/Masonry";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./CSS/Gallary.css";

// const API_BASE_URL = "https://pjsofttech.in:28443";

// export const Galleries = ({ showLimited = false, hideFilters = false }) => {
//   const [galleries, setGalleries] = useState([]);
//   const [filteredGalleries, setFilteredGalleries] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedYear, setSelectedYear] = useState("");
//   const [selectedEvent, setSelectedEvent] = useState("");
//   const institutecode = useContext(InstitutecodeContext);
//   const code = institutecode.data.institutecode;

//   useEffect(() => {
//     AOS.init({ duration: 1000, once: true }); // Initialize AOS animation
//   }, []);

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/getAllGalleries`, { params: { institutecode: code } })
//       .then((response) => {
//         setGalleries(response.data);
//         setFilteredGalleries(response.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching gallery data:", error);
//         setLoading(false);
//       });
//   }, [code]);

//   const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
//   const eventNames = [...new Set(galleries.map((gallery) => gallery.eventName))];

//   useEffect(() => {
//     let filtered = galleries;
//     if (selectedYear) {
//       filtered = filtered.filter((gallery) => gallery.year === selectedYear);
//     }
//     if (selectedEvent) {
//       filtered = filtered.filter((gallery) => gallery.eventName === selectedEvent);
//     }
//     setFilteredGalleries(filtered);
//   }, [selectedYear, selectedEvent, galleries]);

//   const displayedGalleries = showLimited ? filteredGalleries.slice(0, 10) : filteredGalleries;

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <Typography variant="h6" color="textSecondary">
//           Loading...
//         </Typography>
//       </Box>
//     );
//   }

//   if (filteredGalleries.length === 0) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <Typography variant="h6" color="textSecondary">
//           No photos available at the moment.
//         </Typography>
//       </Box>
//     );
//   }

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 8000,
//   };

//   return (
//     <div>
//       {/* Responsive Filters */}
//       {!hideFilters && (
//         <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="center" gap={2} mb={2} sx={{ marginTop: "15px" }}>
//           <TextField
//             select
//             label="Filter by Year"
//             value={selectedYear}
//             onChange={(e) => setSelectedYear(e.target.value)}
//             variant="outlined"
//             sx={{ minWidth: { xs: "100%", sm: 200 } }}
//           >
//             <MenuItem value="">All Years</MenuItem>
//             {years.map((year) => (
//               <MenuItem key={year} value={year}>
//                 {year}
//               </MenuItem>
//             ))}
//           </TextField>

//           <TextField
//             select
//             label="Filter by Event"
//             value={selectedEvent}
//             onChange={(e) => setSelectedEvent(e.target.value)}
//             variant="outlined"
//             sx={{ minWidth: { xs: "100%", sm: 200 } }}
//           >
//             <MenuItem value="">All Events</MenuItem>
//             {eventNames.map((event) => (
//               <MenuItem key={event} value={event}>
//                 {event}
//               </MenuItem>
//             ))}
//           </TextField>
//         </Box>
//       )}

//       {/* Masonry Grid for Galleries */}
//       <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
//         {displayedGalleries.map((gallery) => (
//           <Box key={gallery.galleryId} className="gallery-card" data-aos="zoom-in">
//             <div className="image-container">
//               <Slider {...sliderSettings}>
//                 {gallery.galleryImages.map((image, index) => (
//                   <div key={index} className="image-wrapper">
//                     <img className="gallery-image" src={image} alt={gallery.eventName} />
//                     <div className="hover-overlay">
//                       <Typography variant="h6">{gallery.eventName}</Typography>
//                       <Typography variant="body2">{gallery.year}</Typography>
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </Box>
//         ))}
//       </Masonry>
//     </div>
//   );
// };

// export default Galleries;
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Grid, TextField, MenuItem, Box, Typography } from "@mui/material";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import Masonry from "@mui/lab/Masonry";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/Gallary.css";

const API_BASE_URL = "https://pjsofttech.in:28443";

export const Galleries = ({ showLimited = false, hideFilters = false }) => {
  const [galleries, setGalleries] = useState([]);
  const [filteredGalleries, setFilteredGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const institutecode = useContext(InstitutecodeContext);
  const code = institutecode?.data?.institutecode || "";

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/getAllGalleries`, { params: { institutecode: code } })
      .then((response) => {
        setGalleries(response.data);
        setFilteredGalleries(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching gallery data:", error);
        setLoading(false);
      });
  }, [code]);

  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
  const eventNames = [...new Set(galleries.map((gallery) => gallery?.eventName))];

  useEffect(() => {
    let filtered = galleries.filter(
      (gallery) =>
        (!selectedYear || gallery?.year === selectedYear) &&
        (!selectedEvent || gallery?.eventName === selectedEvent)
    );
    setFilteredGalleries(filtered);
  }, [selectedYear, selectedEvent, galleries]);

  useEffect(() => {
    if (filteredGalleries.length > 0) {
      setImagesLoaded(true);
    }
  }, [filteredGalleries]);

  const displayedGalleries = showLimited ? filteredGalleries.slice(0, 10) : filteredGalleries;

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (filteredGalleries.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="textSecondary">
          No photos available at the moment.
        </Typography>
      </Box>
    );
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  const handleImageLoad = () => {
    setImagesLoaded(true);
    window.dispatchEvent(new Event("resize")); // Forces Masonry to recalculate layout
  };

  return (
    <div>
      {!hideFilters && (
        <Grid container spacing={2}  sx={{ mb: 2, mt: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Filter by Year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">All Years</MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Filter by Event"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">All Events</MenuItem>
              {eventNames.map((event) => (
                <MenuItem key={event} value={event}>
                  {event}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}

      {imagesLoaded && (
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2} sx={{ display: "flex" }}>
          {displayedGalleries.map((gallery) => (
            <Box key={gallery.galleryId} className="gallery-card">
              <div className="image-container">
                <Slider {...sliderSettings}>
                  {gallery.galleryImages.map((image, index) => (
                    <div key={index} className="image-wrapper">
                      <img className="gallery-image" src={image} alt={gallery.eventName} onLoad={handleImageLoad} />
                      <div className="hover-overlay">
                        <Typography variant="h6">{gallery.eventName}</Typography>
                        <Typography variant="body2">{gallery.year}</Typography>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Box>
          ))}
        </Masonry>
      )}
    </div>
  );
};

export default Galleries;
