import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { getVisionMissionByInstituteCode } from "./API/AllApi";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Grid,
  Avatar,
} from "@mui/material";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import "./CSS/Contact.css";

const VisionMission = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchData = async () => {
      try {
        const result = await getVisionMissionByInstituteCode(
          institutecode.data.institutecode
        );
        setData(result?.data || result);
      } catch (err) {
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [institutecode.data.institutecode]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box mt={4}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box
      mt={2}
      mx="auto"
      maxWidth="1200px"
      marginBottom="25px"
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "40px", marginBottom: "40px" }}
        data-aos="fade-down"
      >
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginLeft: "240px",
          }}
        ></div>
        <Typography
          variant="h4"
          style={{
            color: "#0086B9",
            fontWeight: "bold",
            textAlign: "center",
            margin: "0 20px",
          }}
        >
          Vision and Mission
        </Typography>
        <div
          style={{
            flex: "1",
            height: "3px",
            backgroundColor: "#0086B9",
            marginRight: "240px",
          }}
        ></div>
      </Box>

      <Grid container spacing={4} justifyContent="center" data-aos="fade-down">
        {/* Director Information Card */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              border: "1px solid #e0e0e0", // Light border around the card
              borderRadius: "8px", // Rounded corners for the box
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Box shadow for depth effect
              padding: "20px", // Padding inside the box
            }}
          >
            <Grid container spacing={4} justifyContent="center">
              {/* Director's Image and Name */}
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {/* Director's Image */}
                <Avatar
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  src={data?.directorImage || ""}
                  alt={data?.directorName || "Director"}
                  sx={{
                    width: { xs: 80, sm: 120 }, // Adjust image size for smaller screens
                    height: { xs: 80, sm: 120 }, // Adjust image size for smaller screens
                  }}
                />

                {/* Director's Name */}
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data?.directorName || "No director name provided."}
                </Typography>
              </Grid>

              {/* Director's Message and Description */}
              <Grid item xs={12} md={9}>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",  // Align items to the left
      textAlign: "left",         // Ensure text is aligned to the left
      px: { xs: 2, md: 4 },      // Padding on the sides for responsiveness
    }}
  >
    {/* Director's Message */}
    <Typography variant="body1" sx={{ mb: 2, width: "100%" }}>
      {data?.directorMessage || "No director message provided."}
    </Typography>

    {/* Director's Description */}
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{ mt: 2, width: "100%" }}
    >
      {data?.description || "No description provided."}
    </Typography>
  </Box>
</Grid>

            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={8} elevation={10} style={{ marginTop: "10px" }}>
        <Grid item xs={12} md={6} data-aos="fade-right">
          <Card>
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom style={{color: "#0086B9", fontWeight: "bold"}}>
                Vision
              </Typography>
              <Typography variant="body1">
                {data?.vision || "No vision provided."}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-left">
          <Card>
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom style={{color: "#0086B9", fontWeight: "bold"}}>
                Mission
              </Typography>
              <Typography variant="body1">
                {data?.mission || "No mission provided."}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <RotatingImage />
      <TopLeftImage />
      <BottomRightImage />
    </Box>
  );
};

const RotatingImage = () => (
  <Box
    style={{
      position: "absolute",
      marginLeft: "-70px",
      marginTop: "-400px",
      right: "600px",
      left: "8px",
      width: "120px",
      height: "120px",
      zIndex: -10,
    }}
    data-aos="zoom-in"
  ></Box>
);

const TopLeftImage = () => (
  <Box
    style={{
      position: "absolute",
      top: "7px",
      left: "-70px",
      width: "150px",
      height: "150px",
      zIndex: -10,
    }}
    data-aos="fade-right"
  ></Box>
);

const BottomRightImage = () => (
  <Box
    style={{
      position: "absolute",
      bottom: "20px",
      right: "-35px",
      width: "120px",
      height: "120px",
      zIndex: -10,
    }}
    data-aos="fade-left"
  ></Box>
);

export default VisionMission;
