import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Grid } from "@mui/material";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";

const JobBoard = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1200,  // ✅ Longer duration for smooth effect
      easing: "ease-out-back", // ✅ Adds a slight bounce effect
      once: true, // ✅ Ensures animation happens only once
    });
  }, []);

  useEffect(() => {
    fetch("./data/jobs.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched jobs:", data);
        setJobs(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading jobs:", error);
        setLoading(false);
      });
  }, []);

  const handleApply = (jobTitle) => {
    Swal.fire({
      title: "Applied!",
      text: `You have successfully applied for ${jobTitle}.`,
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  };

  if (loading) return <p>Loading jobs...</p>;
  if (jobs.length === 0) return <p>No jobs available</p>;

  return (
    <Grid container spacing={3} padding={3}>
      {jobs.map((job, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{ boxShadow: 3, borderRadius: 3 }}
            data-aos="zoom-in" // ✅ Zoom-in effect
            data-aos-delay={index * 100} // ✅ Staggered animation
          >
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                Job Details
              </Typography>
              <Typography>
                <strong>Apply for:</strong> {job.title}
              </Typography>
              <Typography>
                <strong>Number of Posts:</strong> {job.posts}
              </Typography>
              <Typography>
                <strong>Salary:</strong> {job.salary}
              </Typography>
              <Typography>
                <strong>Experience:</strong> {job.experience}
              </Typography>
              <Typography>
                <strong>Skills Required:</strong> {job.skills}
              </Typography>
              <Typography>
                <strong>Location:</strong> {job.location}
              </Typography>
              <Grid container spacing={2} marginTop={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApply(job.title)}
                  >
                    APPLY
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button variant="outlined">VIEW</Button>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default JobBoard;
