import React, { useEffect, useState, useContext } from "react";
import { getAllJobCareerOptions } from "./API/AllApi";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
// import { DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from "sweetalert2";

const JobCareerList = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [applicant, setApplicant] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedHR, setSelectedHR] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [resume, setResume] = useState(null);
  const institutecode = useContext(InstitutecodeContext);
  

  
  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchJobs = async () => {
      try {
        const data = await getAllJobCareerOptions(
          institutecode.data.institutecode
        );
        setJobs(data);
        setFilteredJobs(data);
      } catch (err) {
        setError("Failed to fetch job career options");
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [institutecode.data.institutecode]);

  const handleApplyClick = (job) => {
    setSelectedJob(job);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setApplicant({ name: "", email: "", mobile: "" });
    setResume(null);
  };

  const handleApplySubmit = () => {
    Swal.fire({
      title: "Applied!",
      text: `You have successfully applied for ${selectedJob.title}.`,
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
    handleDialogClose();
  };

  // const handleContactClick = (job) => {
  //   setSelectedHR(job.hrDetails);
  //   setOpenContactDialog(true);
  // };


  const handleContactClick = async (job) => {
    try {
      const response = await fetch(`https://pjsofttech.in:28443/getHrDetailsByJobCareerOption/${job.id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch HR details");
      }
      const hrDetails = await response.json();
  
      // Assuming hrDetails contains { name, email, contact }
      setSelectedHR(hrDetails);
      setOpenContactDialog(true);
    } catch (error) {
      console.error("Error fetching HR details:", error);
    }
  };

  const handleContactDialogClose = () => {
    setOpenContactDialog(false);
  };

  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setResume(file);
    } else {
      Swal.fire("Invalid file type", "Please upload a PDF file", "error");
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        style={{
          color: "#0086B9",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
        data-aos="fade-down"
      >
        Career
      </Typography>

      <Grid container spacing={2}>
        {filteredJobs.length > 0 ? (
          filteredJobs.map((job) => (
            <Grid item xs={12} sm={6} md={4} key={job.id} data-aos="fade-up">
              <Card
                style={{
                  backgroundColor: job.JobCareerOptionColor,
                  padding: "10px",
                }}
              >
                <CardContent>
                  <Typography variant="h6">{job.title}</Typography>
                  <Typography variant="body2" style={{ marginBottom: "3px" }}>
                    {job.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Location:</b> {job.location}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Salary Range:</b> {job.salaryRange}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Responsibilities:</b> {job.responsibilities}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <b>Last Date to Apply:</b> {job.lastDateToApply}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    marginTop={2}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleApplyClick(job)}
                    >
                      APPLY
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleContactClick(job)}
                    >
                      CONTACT
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", width: "100%" }}
          >
            No results found.
          </Typography>
        )}
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Apply for {selectedJob?.title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            margin="dense"
            value={applicant.name}
            onChange={(e) =>
              setApplicant({ ...applicant, name: e.target.value })
            }
            error={!applicant.name && validationError}
            helperText={
              !applicant.name && validationError ? "Name is required" : ""
            }
          />
          <TextField
            fullWidth
            label="Email"
            margin="dense"
            value={applicant.email}
            onChange={(e) =>
              setApplicant({ ...applicant, email: e.target.value })
            }
            error={!applicant.email && validationError}
            helperText={!applicant.email ? "Required" : ""}
          />
          <TextField
            fullWidth
            label="Mobile Number"
            margin="dense"
            value={applicant.mobile}
            onChange={(e) =>
              setApplicant({ ...applicant, mobile: e.target.value })
            }
            error={!applicant.mobile && validationError}
            helperText={!applicant.mobile ? "Required" : ""}
          />
          <input
            type="file"
            accept="application/pdf"
            onChange={handleResumeUpload}
            style={{ marginTop: "10px" }}
          />
          {resume && (
            <Typography variant="body2" color="textSecondary">
              Uploaded Resume: {resume.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (
                !applicant.name ||
                !applicant.email ||
                !applicant.mobile ||
                !resume
              ) {
                setValidationError(true);
                return;
              }
              setValidationError(false);
              handleApplySubmit();
            }}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={openContactDialog}
  onClose={handleContactDialogClose}
  maxWidth="md"
>
  <DialogTitle>HR Contact Details</DialogTitle>
  <DialogContent>
    {selectedHR ? (
      <>
        <Typography>
          <b>Name:</b> {selectedHR.hrName}
        </Typography>
        <Typography>
          <b>Email:</b> {selectedHR.email}
        </Typography>
        <Typography>
          <b>Contact:</b> {selectedHR.contact}
        </Typography>
      </>
    ) : (
      <Typography>Loading...</Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleContactDialogClose} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>
    </Container>
  );
};

export default JobCareerList;
