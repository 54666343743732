// // App.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import InstitutecodeProvider from './Components/InstitutecodeProvider'; // Import the provider
// import Navbar from './Components/Navbar';
// import Home from './Components/NavbarItemes/Home';
// import Toppers from './Components/Topper';
// import Courses from './Components/Courses';
// import Testimonials from './Components/Testimonials';
// import MapAndImages from './Components/ContactUs';
// import Footer from './Components/Footer';
// import Faculties from './Components/Faculty';
// import Galleries from './Components/Gallary';
// import AboutUs from './Components/AboutUs';
// import VisionMission from './Components/VisionMission';

// const App = () => {
//   return (
//     <InstitutecodeProvider>
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           minHeight: '100vh',
//         }}
//       >
//         <Navbar />
//         <main style={{ flex: 1 }}>
//           <Routes>
//             <Route path="/website" element={<Navigate to="/website/home" />} />
//             <Route path="/website/home" element={<Home />} />
//             <Route path="/website/about" element={<h1>About Page</h1>} />
//             <Route path="/website/faculty" element={<Faculties />} />
//             <Route path="/website/toppers" element={<Toppers />} />
//             <Route path="/website/courses" element={<Courses showAllCourses={true} />} />
//             <Route path="/website/testimonials" element={<Testimonials showOnHome={false} />} />
//             <Route path="/website/gallery" element={<Galleries />} />
//             <Route path="/website/vision" element={<VisionMission />} />
//             <Route path="/website/about-us" element={<AboutUs />} />
//             <Route path="/website/contact-us" element={<MapAndImages />} />
//           </Routes>
//         </main>
//         <Footer />
//       </div>
//     </InstitutecodeProvider>
//   );
// };

// const AppWrapper = () => (
//   <Router>
//     <App />
//   </Router>
// );

// export default AppWrapper;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import InstitutecodeProvider, { InstitutecodeContext } from './Components/InstitutecodeProvider'; 
import Navbar from './Components/Navbar';
import Home from './Components/NavbarItemes/Home';
import Awards from './Components/AwardsList';
import Toppers from './Components/Topper';
import JobBoard from "./Components/JobBoard";
import Courses from './Components/Courses';
import Testimonials from './Components/Testimonials';
import MapAndImages from './Components/ContactUs';
import Footer from './Components/Footer';
import Faculties from './Components/Faculty';
import Galleries from './Components/Gallary';
import AboutUs from './Components/AboutUs';
import VisionMission from './Components/VisionMission';
import "@fontsource/poppins"; // Defaults to 400 weight
import "@fontsource/poppins/700.css"; // Example for bold text
import "./index.css"; // or import "./App.css";
import theme from './Components/theme';
import { ThemeProvider } from '@emotion/react';
import AwardsList from './Components/AwardsList';
import "@mui/lab/themeAugmentation"; // Ensures MUI Lab components work properly

const AppContent = () => {
  const institutecode = useContext(InstitutecodeContext);

  if (!institutecode) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <main style={{ flex: 1 }}>
        <Routes>
          <Route path="/website" element={<Navigate to="/website/home" />} />
          <Route path="/website/home" element={<Home />} />
          <Route path="/website/about" element={<h1>About Page</h1>} />
          <Route path="/website/faculty" element={<Faculties />} />
          <Route path="/website/awards" element={<AwardsList/>} />
          <Route path="/website/toppers" element={<Toppers />} />
          <Route path="/website/JobBoard" element={<JobBoard />} />
          <Route path="/website/courses" element={<Courses showAllCourses={true} />} />
          <Route path="/website/testimonials" element={<Testimonials showOnHome={false} />} />
          <Route path="/website/gallery" element={<Galleries />} />
          <Route path="/website/vision" element={<VisionMission />} />
          <Route path="/website/about-us" element={<AboutUs />} />
          <Route path="/website/contact-us" element={<MapAndImages />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const App = () => (
  <ThemeProvider theme={theme}> {/* Wrap the entire app */}
  <Router>
    <InstitutecodeProvider>
      <AppContent />
    </InstitutecodeProvider>
  </Router>
  </ThemeProvider>
);

export default App;
