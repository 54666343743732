// import React from "react";
// import { useLocation } from "react-router-dom";
// import { Box, Typography, Card, CardMedia, CardContent } from "@mui/material";

// const CourseDetails = () => {
//   const location = useLocation();
//   const { course } = location.state || {};

//   if (!course) {
//     return <Typography variant="h5">Course not found.</Typography>;
//   }

//   return (
//     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//       <Card sx={{ maxWidth: 600 }}>
//         <CardMedia
//           component="img"
//           height="200"
//           image={course.courseImage || "/path/to/fallback-image.jpg"}
//           alt={course.courseTitle}
//         />
//         <CardContent>
//           <Typography variant="h4">{course.courseTitle}</Typography>
//           <Typography variant="body1">{course.description}</Typography>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default CourseDetails;

import React from "react";
import { Typography, Box, Button } from "@mui/material";

const CourseDetails = ({ course, handleClose }) => {
  if (!course) return null;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={3}>
      <img
        src={course.courseImage || "/path/to/fallback-image.jpg"}
        alt={course.courseTitle}
        style={{ width: "100%", maxHeight: "300px", objectFit: "cover", borderRadius: "8px" }}
      />
      <Typography variant="h4" style={{ marginTop: "20px" }}>
        {course.courseTitle}
      </Typography>
      <Typography variant="body1" style={{ marginTop: "10px", textAlign: "center" }}>
        {course.description || "No description available."}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClose} style={{ marginTop: "20px" }}>
        Back
      </Button>
    </Box>
  );
};

export default CourseDetails;