import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
import {
  fetchFooterByInstitutecode,
  fetchCoursesAPI,
  fetchNavbarData,
} from "./API/AllApi";
import { useNavigate } from "react-router-dom";
import "./CSS/Footer.css";
import playStore from "../Images/google-play-store-app-logo-gets-a-slight-redesign-png-19.png";
import appStore from "../Images/app-store-png-logo-33116.png";
import facebook from "../Images/facebook-logo-icon-facebook-icon-png-images-icons-and-png-backgrounds-6.png";
import instagram from "../Images/logo-ig-logo-instagram-ini-ada-varias-dan-transparan-33.png";
import twitter from "../Images/512x512-transparent-logo-download-twitter-png-transparent-image-and-clipart-8.png";
import youtube from "../Images/youtube-play-button-icon-icons-and-png-backgrounds-25.png";
import whatsApp from "../Images/whatsapp-logo-png-2266.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import logo from "./logo.jpg";

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [courses, setCourses] = useState([]);
  const [footerTitle, setFooterTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [navbarImage, setNavbarImage] = useState("");
  const navigate = useNavigate();
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    if (!institutecode.data.institutecode) {
      return; // Do not proceed if institutecode is not set
    }

    const fetchFooterData = async () => {
      try {
        const footer = await fetchFooterByInstitutecode(
          institutecode.data.institutecode
        );
        const coursesData = await fetchCoursesAPI(
          institutecode.data.institutecode
        );
        setFooterData(footer);
        setCourses(coursesData.slice(0, 9)); // Limit to 9 courses for 3 rows
        setFooterTitle(footer.title || "Default Footer Title");
      } catch (err) {
        console.error("Error fetching footer or courses data:", err);
        setError("Failed to load footer data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFooterData();
  }, [institutecode.data.institutecode]);

  useEffect(() => {
    if (!institutecode.data.institutecode) {
      return; // Do not proceed if institutecode is not set
    }

    const getNavbarData = async () => {
      try {
        const data = await fetchNavbarData(institutecode.data.institutecode);
        setNavbarImage(data?.navbarImage || "");
      } catch (error) {
        console.error("Failed to fetch navbar data:", error);
      }
    };

    getNavbarData();
  }, [institutecode.data.institutecode]);

  const getTextColor = (bgColor) => {
    if (!bgColor) return "black"; // Default to black
    const color = bgColor.replace("#", "");
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    // Calculate relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 128 ? "black" : "white"; // Choose black or white based on luminance
  };

  if (loading) {
    return (
      <Container>
        <Typography align="center">Loading footer...</Typography>
        <CircularProgress color="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography align="center" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!footerData) {
    return null;
  }

  const footerBgColor = footerData.footerColor || "gray";
  const textColor = getTextColor(footerBgColor);
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        backgroundColor: footerBgColor,
        color: textColor,
        padding: "0px",
        marginTop: "30px",
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="start"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={12} sm={8}>
          <Typography
            variant="h6"
            align="center"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              color: textColor,
            }}
          >
            Courses
          </Typography>
          <Divider
            style={{ marginBottom: "20px", backgroundColor: textColor }}
          />
          <Grid container spacing={1}>
            {courses.slice(0, 9).map((course) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={course.courseId}
                onClick={() => navigate("/courses")}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: textColor,
                }}
              >
                <Typography variant="subtitle1">
                  {course.courseTitle}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Contact Us Section */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            align="center"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              color: textColor,
            }}
          >
            Contact Us
          </Typography>
          <Divider
            style={{ marginBottom: "20px", backgroundColor: textColor }}
          />
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              color: textColor,
              justifyContent: "flex-start", // Ensure the content is aligned to the left
            }}
          >
            <LocationOnIcon style={{ marginRight: "8px", color: "red" }} />
            <span style={{ textAlign: "left" }}>
              Address: {footerData.address || "N/A"}
            </span>
          </Typography>

          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              color: textColor,
            }}
          >
            <EmailIcon style={{ marginRight: "8px", color: "orange" }} />
            Email: {footerData.email || "info@example.com"}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              color: textColor,
            }}
          >
            <CallIcon style={{ marginRight: "8px", color: "" }} />
            Phone: {footerData.mobileNumber || "+1 (123) 456-7890"}
          </Typography>
        </Grid>
      </Grid>

      {/* Footer Details */}

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Left Section: Navbar Image and Footer Title */}
        <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
          {navbarImage && (
            <img
              src={navbarImage}
              alt="Navbar Logo"
              className="navbar-logo"
              style={{
                borderRadius: "50%", // Circular image
                width: "70px", // Adjust size
                height: "70px",
                objectFit: "cover",
                marginRight: "10px",
                marginBottom: "-10px",
              }}
            />
          )}
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: navbarImage ? "10px" : "0",
              marginBottom: "40px",
            }}
          >
            {footerTitle}
          </Typography>
        </Grid>

        {/* Center Section: Social Media Icons */}
        <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "15px" }}
          >
            <IconButton
              component="a"
              href={footerData.instagramLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.facebookLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.twitterLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" style={{ width: "30px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.youtubeLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="YouTube" style={{ width: "33px" }} />
            </IconButton>
            <IconButton
              component="a"
              href={footerData.whatsAppLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsApp} alt="WhatsApp" style={{ width: "50px" }} />
            </IconButton>
          </div>
        </Grid>

        {/* Right Section: App Store and Google Play Links */}
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "15px" }}
          >
            <a
              href={footerData.googlePlayLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton size="small">
                <img
                  src={playStore}
                  alt="Google Play"
                  style={{ width: "50px" }}
                />
              </IconButton>
            </a>
            <a
              href={footerData.appStoreLink || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton size="small">
                <img
                  src={appStore}
                  alt="App Store"
                  style={{ width: "100px" }}
                />
              </IconButton>
            </a>
          </div>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px", backgroundColor: textColor }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            color: textColor,
          }}
        >
          Copyright© {currentYear} All Rights Reserved.
        </Typography>

        <Typography
          style={{
            fontSize: "14px",
            color: textColor,
          }}
        >
          Made with <span style={{ color: "red" }}>❤️</span> by{" "}
          <a
            href="https://www.pjsofttech.com/solutions"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: textColor,
              fontWeight: "bold",
            }}
          >
            PJSOFTTECH
          </a>
          <img
            src={logo}
            alt="Footer Logo"
            style={{
              width: "22px",
              height: "13px",
              marginLeft: "5px",
            }}
          />
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
