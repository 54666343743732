

// import React, { useContext, useEffect, useState } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { Container, Box, CircularProgress, Typography, Paper, Divider } from "@mui/material";
// import { fetchMapAndImagesAPI } from "./API/AllApi";
// import ContactForm from "./ContactForm";
// import { InstitutecodeContext } from "./InstitutecodeProvider";
// import "./CSS/Contact.css";

// const MapAndImages = () => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [imageError, setImageError] = useState(false);

//   const institutecode = useContext(InstitutecodeContext);

//   useEffect(() => {
//     AOS.init({ duration: 1200 }); // Initialize AOS
    
//     const fetchData = async () => {
//       try {
//         const result = await fetchMapAndImagesAPI(institutecode.data.institutecode);
//         setData(result);
//       } catch (err) {
//         setError("Failed to load data. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [institutecode]);

//   if (loading) {
//     return (
//       <Container>
//         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//           <CircularProgress color="primary" />
//           <Typography variant="body1" style={{ marginLeft: "1rem" }}>Loading data...</Typography>
//         </Box>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//           <Typography variant="h6" color="error">{error}</Typography>
//         </Box>
//       </Container>
//     );
//   }

//   if (!data) {
//     return (
//       <Container>
//         <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//           <Typography variant="h4" color="textSecondary">No data available at the moment.</Typography>
//         </Box>
//       </Container>
//     );
//   }

//   const mapIframe = data.maps.replace(/width="600"/, 'width="100%"').replace(/height="450"/, 'height="100%"');

//   return (
//     <Box display="flex" flexDirection="column" alignItems="center" width="100%">
//       <Box display="flex" width="100%" marginBottom="20px" position="relative" data-aos="fade-up">
//         <Box
//           style={{
//             marginTop: "120px",
//             width: "40%",
//             maxHeight: "400px",
//             height: "700px",
//             overflow: "hidden",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginLeft: "100px",
//           }}
//         >
//           <img
//             src={!imageError ? data.contactImage : "/path/to/fallback-image.jpg"}
//             alt="Institute"
//             style={{
//               width: "100%",
//               height: "500px",
//               objectFit: "cover",
//             }}
//             onError={() => setImageError(true)}
//             data-aos="zoom-in"
//           />
//         </Box>
//         <ContactForm data-aos="fade-left" />
//       </Box>

//       <Divider sx={{ border: "1.5px solid black", width: "90%", marginTop: "60px", color: '#0086B9', marginBottom: "30px" }} />

//       <Typography variant="h4" style={{ fontWeight: "bold", textAlign: "center", margin: "0 20px", color:'#0086B9' }} data-aos="fade-up">Our Location</Typography>

//       <Paper elevation={3} style={{ width: "100%", maxWidth: "1200px", height: "500px", margin: "30px auto" }} data-aos="fade-up">
//         <div dangerouslySetInnerHTML={{ __html: mapIframe }} style={{ width: "100%", height: "100%", border: "1.5px solid black" }} />
//       </Paper>
//     </Box>
//   );
// };

// export default MapAndImages;

import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Box, CircularProgress, Typography, Paper, Divider } from "@mui/material";
import { fetchMapAndImagesAPI } from "./API/AllApi";
import ContactForm from "./ContactForm";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import "./CSS/Contact.css";

const MapAndImages = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageError, setImageError] = useState(false);
  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS animations

    const fetchData = async () => {
      try {
        const result = await fetchMapAndImagesAPI(institutecode.data.institutecode);
        setData(result);
      } catch (err) {
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [institutecode]);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress color="primary" />
          <Typography variant="body1" sx={{ ml: 2 }}>Loading data...</Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      </Container>
    );
  }

  if (!data) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h4" color="textSecondary">No data available at the moment.</Typography>
        </Box>
      </Container>
    );
  }

  const mapIframe = data.maps.replace(/width="600"/, 'width="100%"').replace(/height="450"/, 'height="100%"');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box 
        display="flex" 
        flexDirection={{ xs: "column", md: "row" }} 
        width="100%" 
        mb={3} 
        position="relative" 
        data-aos="fade-up"
        alignItems="center"
      >
        {/* Image Section */}
        <Box
          sx={{
            mt: { xs: 2, md: "120px" },
            width: { xs: "90%", md: "40%" },
            maxHeight: "400px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
          }}
        >
          <img
            src={!imageError ? data.contactImage : "/path/to/fallback-image.jpg"}
            alt="Institute"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              maxHeight: "500px",
            }}
            onError={() => setImageError(true)}
            data-aos="zoom-in"
          />
        </Box>

        {/* Contact Form Section */}
        <Box width={{ xs: "90%", md: "50%" }} mx="auto">
          <ContactForm data-aos="fade-left" />
        </Box>
      </Box>

      <Divider sx={{ border: "1.5px solid black", width: "90%", mt: 4, color: "#0086B9", mb: 3 }} />

      <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center", mx: 2, color: "#0086B9" }} data-aos="fade-up">
        Our Location
      </Typography>

      <Paper 
        elevation={3} 
        sx={{ width: "90%", maxWidth: "1200px", height: { xs: "300px", md: "500px" }, mt: 3, mb: 3 }}
        data-aos="fade-up"
      >
        <div 
          dangerouslySetInnerHTML={{ __html: mapIframe }} 
          style={{ width: "100%", height: "100%", border: "1.5px solid black" }} 
        />
      </Paper>
    </Box>
  );
};

export default MapAndImages;
