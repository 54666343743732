import React, { useContext, useEffect, useState } from "react";
import { InstitutecodeContext } from "./InstitutecodeProvider";
import "./CSS/Navbar.css"; // Separate CSS file
import { fetchNavbarData } from "./API/AllApi";
import Home from "./NavbarItemes/Home";
import Courses from "./Courses";
import Faculties from "./Faculty";
import Topper from "./Topper";
import JobCareerList from "./JobCareerList";
import Testimonials from "./Testimonials";
import Galleries from "./Gallary";
import VisionMission from "./VisionMission";
import AboutUs from "./AboutUs";
import MapAndImages from "./ContactUs";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AwardsList from "./AwardsList";
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
  const [navbarColor, setNavbarColor] = useState("#333");
  const [menubarImage, setMenubarImage] = useState("");
  const [fontColor, setFontColor] = useState("#fff"); // Default font color
  const [currentView, setCurrentView] = useState("home"); // State for current view
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu

  const menuItems = [
    { label: "Home", component: <Home setCurrentView={setCurrentView} />, icon: <HomeOutlinedIcon /> },
    {
      label: "Courses",
      component: <Courses showAllCourses={true} />,
      icon: <FeaturedPlayListOutlinedIcon />,
    },
    {
      label: "Faculty",
      component: <Faculties />,
      icon: <CoPresentOutlinedIcon />,
    },
    {
      label: "Awards",
      component: <AwardsList />,
      icon: <EmojiEventsOutlinedIcon />,
    },
    {
      label: "Toppers",
      component: <Topper />,
      icon: <MilitaryTechOutlinedIcon />,
    },
    {
      label: "Jobs",
      component: <JobCareerList />,
      icon: <WorkOutlineOutlinedIcon />,
    },
    {
      label: "Testimonials",
      component: <Testimonials showOnHome={false} />,
      icon: <CommentBankOutlinedIcon />,
    },
    {
      label: "Gallery",
      component: <Galleries />,
      icon: <CollectionsOutlinedIcon />,
    },
    {
      label: "Vision",
      component: <VisionMission />,
      icon: <RocketLaunchOutlinedIcon />,
    },
    { 
      label: "About Us", 
      component: <AboutUs />, 
      icon: <InfoOutlinedIcon /> 
    },
    {
      label: "Contact Us",
      component: <MapAndImages />,
      icon: <ConnectWithoutContactOutlinedIcon />,
    },
  ];

  const institutecode = useContext(InstitutecodeContext);

  useEffect(() => {
    const getNavbarData = async () => {
      try {
        const data = await fetchNavbarData(institutecode.data.institutecode);
        console.log(institutecode.data.institutecode);
        const bgColor = data?.navbarColor || "#333";
        setNavbarColor(bgColor);
        setMenubarImage(data?.navbarImage || "");   
        setFontColor(isColorLight(bgColor) ? "#000" : "#fff"); // Determine font color
      } catch (error) {
        console.error("Failed to fetch navbar data:", error);
      }
    };

    getNavbarData();
  }, [institutecode.data.institutecode]);

  // Function to calculate if a color is light or dark
  const isColorLight = (color) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Calculate luminance: (0.299*R + 0.587*G + 0.114*B)
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance > 186; // A threshold for light colors
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <nav
        className="navbar"
        style={{ backgroundColor: navbarColor, color: fontColor }}
      >
        {menubarImage && (
          <img
            src={menubarImage}
            alt="Navbar Logo"
            className="navbar-logo"
            style={{
              borderRadius: "50%", // Makes the image circular
              width: "80px", 
              height: "80px",
              objectFit: "cover", // Ensures the image content fits within the circular frame
            }}
          />
        )}
        <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
        <ul className={`navbar-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={`navbar-item ${
                currentView === item.label.toLowerCase() ? "active" : ""
              }`}
            >
              <button
                onClick={() => {
                  setCurrentView(item.label.toLowerCase());
                  setIsMobileMenuOpen(false); // Close mobile menu on item click
                }}
                className="navbar-link"
                style={{
                  color: fontColor,
                  fontWeight:
                    currentView === item.label.toLowerCase()
                      ? "bold"
                      : "normal",
                  textDecoration:
                    currentView === item.label.toLowerCase()
                      ? "underline"
                      : "none",
                }}
              >
                <span
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {item.icon}
                  {item.label}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="content">
        {
          menuItems.find((item) => item.label.toLowerCase() === currentView)
            ?.component
        }
      </div>
    </div>
  );
};

export default Navbar;



